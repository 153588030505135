import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import depot from "../http/index.js";
import './assets/base.css'

import axios from 'axios'
import VueAxios from 'vue-axios'


// Vue.prototype.$http = axios;
// Vue.config.productionTip = false;
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'







// app.mount('#app')

// depot();


createApp(App).use(VueAxios, axios).use(ElementPlus, { locale }).use(store).use(router).mount("#app");


